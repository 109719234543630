const endpoints = {
  // Function Apps (public)
  invitationdescriptionEndpoint: '/invitationdescription',
  professionAvailableEndpoint: '/professionavailable',
  statetocountiesEndpoint: '/statetocounties',
  versionPublicEndpoint: '/version',

  // Function Apps (token auth)
  createinvitationEndpoint: '/createinvitation',
  createReferralEndpoint: '/createreferral',
  manageRegistrationEndpoint: '/manageregistration',
  myApprovalsEndpoint: '/myapprovals',
  myAvatarEndpoint: '/myavatar',
  myInvitationsEndpoint: '/myinvitations',
  myNetworkEndpoint: '/mynetwork',
  myprofileEndpoint: '/myprofile',
  proxyEndpoint: '/proxy',
  stripeAdministatorEndPoint: '/stripeadministrator',
  stripeCheckoutEndpoint: '/stripecheckout',
  submitApplicationEndpoint: '/submitapplication',
  subscriptionStatusEndpoint: '/subscriptionstatus',
  updateprofileEndpoint: '/updateprofile',
  userLookupEndpoint: '/userlookup',
  usersSearchEndpoint: '/userssearch',
  versionTokenAuthEndpoint: '/version'
}

export { endpoints }
