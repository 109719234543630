import axios from 'axios'
import { AuthServiceInterface, AuthServiceConstants } from '../src/msal/AuthServiceInterface'
import { EnvX, envx } from '../src/environments/EnvX'

class RemoteAccess {
  authService: AuthServiceInterface

  public constructor(authServiceInterface: AuthServiceInterface) {
    this.authService = authServiceInterface
  }

  tokenAuthFunctionApp(
    logHint: string, // logprofile.getProfile
    endpoint: string, // endpoints.myprofileEndpoint
    environmentVariableKey: string, // environmentVariables.MY_PROFILE_KEY
    body: {}, // post body

    hasPrerequisites: Function, // this.isPageLoading = true
    onSuccessWithData: Function, // this.refreshProfileAndProperties(data), this.isPageLoading = false
    onFailureWithError: Function // this.showGenericFailure = true, this.genericFailureErrorMessage = error.message
  ) {
    try {
      if (!hasPrerequisites()) {
        EnvX.log(`❌ ${logHint} → no prerequisites, abort`)
        return
      }
      const envxBaseURL = envx.tokenAuthFunctionsUrl // baseURL
      const axiosInstance = axios.create({
        baseURL: envxBaseURL,
        proxy: false
      })

      this.authService
        .idTokenAsync(`${logHint}`)
        .then((idToken) => {
          EnvX.log(`${logHint} → idToken=${idToken !== '' ? '👍' : '👎'}`)
          axiosInstance.interceptors.request.use(
            (config) => {
              config.headers.Authorization = `Bearer ${idToken}`
              return config
            },
            (error) => error
          )

          axiosInstance
            .post(endpoint, body, {
              params: {
                code: environmentVariableKey
              }
            })
            .then((response) => {
              const data = response.data
              EnvX.log(`✅ ${logHint} → got response: ${JSON.stringify(data)}`)
              if (data.success) {
                onSuccessWithData(data)
              } else {
                EnvX.log(`⁉️ ${logHint} → does not indicate success`)
                onFailureWithError(new Error(data.error), data.errormessage)
              }
            })
            .catch((error) => {
              EnvX.warn(`⏳ ${logHint} → did not get response: ${JSON.stringify(error)}`)
              onFailureWithError(error, error.message)
            })
        })
        .catch((error) => {
          if (AuthServiceConstants.LOGGED_OUT === error.message) {
            EnvX.log(`👎 ${logHint} → logged out`)
          } else {
            EnvX.warn(`🧨 ${logHint} → token exception: ${JSON.stringify(error)}`)
          }
          onFailureWithError(error, error.message)
        })
    } catch (exception) {
      EnvX.error(`💥 ${logHint} → method exception: ${JSON.stringify(exception)}`)
      onFailureWithError(exception, exception instanceof Error ? exception.message : 'method exception')
    }
  }

  public static publicFunctionApp(
    logHint: string, // logprofile.getProfile
    endpoint: string, // endpoints.myprofileEndpoint
    environmentVariableKey: string, // environmentVariables.MY_PROFILE_KEY
    body: {}, // post body

    hasPrerequisites: Function, // this.isPageLoading = true
    onSuccessWithData: Function, // this.refreshProfileAndProperties(data), this.isPageLoading = false
    onFailureWithError: Function // this.showGenericFailure = true, this.genericFailureErrorMessage = error.message
  ) {
    try {
      if (!hasPrerequisites()) {
        EnvX.log(`❌ ${logHint} → no prerequisites, abort`)
        return
      }
      const envxBaseURL = envx.publicFunctionsUrl // baseURL
      const axiosInstance = axios.create({
        baseURL: envxBaseURL,
        proxy: false
      })

      axiosInstance
        .post(endpoint, body, {
          params: {
            code: environmentVariableKey
          }
        })
        .then((response) => {
          const data = response.data
          EnvX.log(`✅ ${logHint} → got response: ${JSON.stringify(data)}`)
          if (data.success) {
            onSuccessWithData(data)
          } else {
            EnvX.log(`⁉️ ${logHint} → does not indicate success`)
            onFailureWithError(new Error(data.error), data.errormessage)
          }
        })
        .catch((error) => {
          EnvX.warn(`⏳ ${logHint} → did not get response: ${JSON.stringify(error)}`)
          onFailureWithError(error, error.message)
        })
    } catch (exception) {
      EnvX.error(`💥 ${logHint} → method exception: ${JSON.stringify(exception)}`)

      onFailureWithError(exception, exception instanceof Error ? exception.message : 'method exception')
    }
  }
}

export { RemoteAccess }
