const environmentVariables = {
  // AzureFunctionsPublic
  INVITATION_DESCRIPTION_KEY: 'rqem6DbownWF6MZUxir0ULrb-hmqoAxCGSvzQkCm1JDeAzFu79XCFg==',
  PROFESSION_AVAILABLE_KEY: 'aUFw_eaIzG2vH4yZ_GlgoJw2hfVty8eodEwR252RFWWyAzFumR87_Q==',
  STATE_TO_COUNTIES_KEY: '5KHOGXOiJytx51fIfIXDpZBo9Yem3p5QE_8NPlbpXg8GAzFusnN1cA==',
  VERSION_PUBLIC_KEY: 'GDydmHkfSnYj2PYv7ias5PQklrGdBbaE7ALNlb4bsvYCAzFuARWWsw==',

  // AzureFunctionsTokenAuth
  CREATE_INVITATION_KEY: 'YCpDQf12Zjc3aq46Dk5WXuCimEY-UJMs2EIV7CxQ5cCxAzFuuGqFOw==',
  CREATE_REFERRAL_KEY: 'crjvFNYlxG82_ykYUqt0r3mJTFieWiy4N1r9LheN8F2PAzFuajUAqg==',
  MANAGE_REGISTRATION_KEY: 'FoBAWOtGCQQqatmPDnLQCZGjZL776wNIlyR1LODt_uwZAzFuPpivWA==',
  MY_APPROVALS_KEY: 'cx-7P5YWTGjZx_zJQO5W6zhTfUT6ufdmSLMucWxJ97OpAzFuKAnwLg==',
  MY_AVATAR_KEY: 'OYszyTHWaD5eNSfCv-y1lOwggmP_fcHlr39IV4wx3HiKAzFuBiEsug==',
  MY_INVITATIONS_KEY: '4jgh6pAsQWgvdlqjuLRi2hhfFupAdz2YlTKnz-WCDRILAzFug4W3xw==',
  MY_NETWORK_KEY: 'Z7NUmUuE3IiQ72OWpi137JuPw9-hv8MSpc42YErcXVECAzFuZKKkww==',
  MY_PROFILE_KEY: 'IndfVpxcKODdGOpkYSPgVt9uEkjvFb531S7Hfj33Gs82AzFuKeHvzg==',
  PROXY_KEY: 'g-dkLt-rfz9lmWZjGZpeEE2HXtBrLSa-gioIbx_L7Z4CAzFujYF_Dg==',
  STRIPE_ADMINISTRATOR_KEY: '3GwyymHrkFM0Pv9EzOUHA-9SKetDK3pGdkKi7ys_jO2aAzFuY7DjGg==',
  STRIPE_CHECKOUT_KEY: 'mESy3ga4B4cc8uOJoglrM1pH4CxSFCi7btSukOb4bqMpAzFuD51teg==',
  SUBMIT_APPLICATION_KEY: 'FfpGCV3NgnOg_sROq8SrTKO4Q8Vmpc7E0XzVEQzL21jfAzFuHI3cPQ==',
  SUBSCRIPTION_STATUS_KEY: 'P3jcvJJaMFr1rOg35Z0kk9n5X5CG6K8n05Nu9WLeXd85AzFuPQhMlQ==',
  UPDATE_PROFILE_KEY: 'ERSLIb_n7GJ4D8T59Q1KjT8l2aa3Ah9198ozt60F9pBfAzFueeJHGw==',
  USER_LOOKUP_KEY: '468D8nf8fb_eD8tlz5B9IEvsfp4FnocZzKtVqzgifib3AzFun7gH-w==',
  USERS_SEARCH_KEY: 'lAvazzb1Yyhk_PWd3dH6XoCgujlYXj_Zj74Yfn6ovWshAzFu1xzfiQ==',
  VERSION_TOKEN_AUTH_KEY: 'aJRDoejwHsE0Bb2ZGBPN72G4p5T98LGLXSWgOEHqjA7yAzFuIw6oVQ==',

  // Terminator
  NONE: ''
}

export { environmentVariables }
